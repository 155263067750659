<template>
  <div class="position-relative">
    <loading v-if="card.loader"></loading>
    <CardForm class="mt-2" :col="6">
      <template #card-section-2>
        <v-col md="6" cols="12" class="mx-auto mb-6">
          <v-card class="card-shadow border-radius-xl">
            <div class="border pa-6">
              <div class="d-flex justify-space-between">
                <h5 class="text-h5 text-typo font-weight-normal">
                  {{ $t("project.work time info") }}
                </h5>
                <Button
                  v-if="work_id != null"
                  @click="add_work"
                  :title="$t('general.add')"
                ></Button>
              </div>
            </div>
            <div class="pa-4 border">
              <v-form
                ref="form_work"
                lazy-validation
                v-model="valid"
                @submit.stop.prevent="handleSubmit"
                class="pt-2"
              >
                <v-row>
                  <v-col cols="12" v-if="work_id">
                    <h4 class="text-md text-typo">
                      {{ $t("general.edit") + " # " + work_id }}
                    </h4>
                  </v-col>
                  <v-col
                    class="py-0"
                    :key="'col-' + ind"
                    v-for="(item, ind) in style_work_count_time"
                    :cols="item.col ? item.col : '6'"
                  >
                    <template
                      v-if="
                        item.hasOwnProperty('visible') ? item.visible : true
                      "
                    >
                      <!-- {{ item.visible }} -->
                      <Input :item="item"></Input>
                    </template>
                  </v-col>
                  <v-col class="pt-0" :class="classLang">
                    <Button type="submit" :loader="loading"></Button>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card>
          <v-card v-if="works.length" class="card-shadow border-radius-xl mt-6">
            <div class="pa-4">
              <item-project-component
                :data="works"
                :edit="true"
                @delete_work="delete_work"
                @edit_work="edit_work"
              ></item-project-component>
            </div>
          </v-card>
        </v-col>
      </template>
    </CardForm>
  </div>
</template>
    <script>
import CardForm from "../Components/CardForm";
import Input from "../Components/Input";
import Button from "../Components/Button";
import projectService from "../../../services/project.service";
import {
  isFormValidate,
  isValueNotEmpty,
  isDropDownComponent,
} from "@/helper/General";
import { style_work } from "./style";
import { mapState } from "vuex";
import ItemProjectComponent from "./item_project_component.vue";
import Loading from "../Components/Loading";
export default {
  name: "New-Project",
  components: {
    CardForm,
    Input,
    Loading,
    Button,
    ItemProjectComponent,
  },
  data() {
    return {
      work_id: null,
      valid: true,
      loading: false,
      works: [],
      style_work_count_time: [],
      style_form: [
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("project.project_name"),
          placeholder: "name",
          error: null,
          value_text: "project_name",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "number",
          label: this.$i18n.t("project.price_of_project"),
          placeholder: "60",
          error: null,
          value_text: "price_of_project",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "number",
          label: this.$i18n.t("project.number_of_hour"),
          placeholder: "100",
          error: null,
          value_text: "number_of_hour",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "6",
          type: "text",
          label: this.$i18n.t("project.number_of_employee"),
          placeholder: "20",
          error: null,
          value_text: "number_of_employee",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
        {
          col: "12",
          type: "autocomplete",
          label: this.$i18n.t("project.contractor"),
          value_text: "contractor_id",
          items: [],
          //   title_select: "name",
          error: null,
        },
        {
          col: "12",
          type: "autocomplete",
          label: this.$i18n.t("project.status"),
          value_text: "status_id",
          items: [],
          title_select: "status_lang",
          error: null,
        },
        {
          col: "12",
          type: "textarea",
          label: this.$i18n.t("project.address_project"),
          error: null,
          value_text: "address_project",
          value: "",
          req: true,
          rules: [(v) => !!v || this.$i18n.t("form.Item is required")],
        },
      ],
      card: {
        title: this.$i18n.t("create project"),
        loader: false,
        sub_title: this.$i18n.t("project.Project info"),
      },
    };
  },
  computed: {
    ...mapState("project", ["project"]),
    classLang() {
      return {
        "text-left": this.$vuetify.rtl,
        "text-right": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    handleSubmit() {
      this.loading = true;
      if (isFormValidate(this.$refs.form_work)) {
        this.loading = false;
        const formData = new FormData();
        this.style_work_count_time.map(function (item) {
          if (isValueNotEmpty(item.value)) {
            if (isDropDownComponent(item.value)) {
              var value = item.value;
              for (var i = 0; i < value.length; i++) {
                const val = value[i];
                formData.append(`${item.value_text}[ ${i} ]`, val);
              }
            } else {
              formData.append(item.value_text, item.value);
            }
          }
        });
        formData.append("project_id", this.$route.params.id);

        if (this.work_id != null)
          var url = "project-work/update/" + this.work_id;
        else var url = "project-work/store";

        axios.post(url, formData).then(
          (response) => {
            this.$refs.form_work.reset();
            if (this.work_id != null) {
              this.works = this.works.filter((v) => v.id != this.work_id);
              this.work_id = null;
            }

            this.works.push(response.data.data);
            this.$store.commit("form/SET_NOTIFY", {
              msg: response.data.message,
              type: "Success",
            });
          },
          (error) => {
            console.log(error);
            this.$store.commit("form/SET_NOTIFY", {
              msg: error.response.data.message,
              type: "Danger",
            });
            // this.$router.push({
            //   name: "Projects",
            //   params: {
            //     message: {
            //       msg: this.$i18n.t("general.there is problem"),
            //       type: "Danger",
            //     },
            //   },
            // });
          }
        );
      } else {
        this.loading = false;
        this.error_msg = this.$i18n.t("Please Check errors before submit");
        this.$store.commit("form/SET_NOTIFY", {
          msg: this.error_msg,
          type: "Danger",
        });
      }
    },
    add_work() {
      this.work_id = null;
      this.$refs.form_work.reset();
    },
    edit_work(item) {
      console.log(item);
      this.work_id = item.id;
      this.style_work_count_time[0].value = item.name;
      this.style_work_count_time[1].value = item.count_employee;
      this.style_work_count_time[2].value = item.start_time;
      this.style_work_count_time[3].value = item.end_time;
    },
    delete_work(item) {
      this.$swal({
        title: this.$i18n.t("general.Are you sure?!"),
        text: this.$i18n.t("You won't be able to revert this!"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$i18n.t("Yes"),
        cancelButtonText: this.$i18n.t("general.No, cancel!"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          return projectService.delete_work(item.id).then(
            (response) => {
              // console.log(response)
              this.works = this.works.filter((v) => v.id != item.id);
              this.$swal.fire(
                this.$i18n.t("Done!"),
                response.data.message,
                "success"
              );
            },
            (error) => {
              // console.log(error);
              this.$swal.fire(
                this.$i18n.t("Error"),
                this.$i18n.t("There error please try again"),
                "error"
              );
            }
          );
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === this.$swal.DismissReason.cancel
        ) {
          this.$swal.fire(
            this.$i18n.t("general.Cancelled"),
            this.$i18n.t("Cancelled Proccess"),
            "error"
          );
        }
      });
    },
    set_data() {
      this.$store.commit("SET_COLLECTION", "project");
      this.$store.commit("SET_FUNCTION", "update");
      this.$store.commit("SET_ID", this.$route.params.id);
      this.$store.commit("SET_CARD", this.card);
      this.$store.commit("SET_CARD_LOADING", false);
      this.style_work_count_time = style_work;
    },
    get_data() {
      this.card.loader = true;
      return projectService.edit(this.$route.params.id).then(
        (response) => {
          this.card.loader = false;
          for (let i = 0; i < this.style_form.length; i++) {
            const element = this.style_form[i];
            element.value = response.data.project[element.value_text];
          }
          this.works = response.data.project.work_count_time;
          this.style_form[4].items = response.data.contractors;
          this.style_form[5].items = response.data.status;
          this.$store.commit("form/SET_FORM_STYLE", this.style_form);
        },
        (error) => {
          console.log(error);
          this.card.loader = false;
          this.$store.commit("form/SET_NOTIFY", {
            msg: this.$i18n.t("general.there is problem"),
            type: "Danger",
          });
          // this.$router.push({
          //   path: "/projects",
          //   params: { message: message },
          // });
        }
      );
    },
  },
  mounted() {
    this.set_data();
    this.get_data();
    document.title = this.$i18n.t("UpdateProject");
  },
};
</script>
    