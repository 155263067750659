<template>
  <div v-if="data.length > 0">
    <v-row v-for="(item, i) in data" :key="i">
      <v-col cols="11">
        <div class="d-flex justify-space-between">
          <div>
            <!-- <span class="text-sm text-typo">{{ $t("name") + " " }} </span> -->
            <span class="text-sm text-typo">{{ item.name }}</span>
          </div>
          <div>
            <span class="text-sm text-typo">{{
              $t("project.count_employee")
            }}</span>
            <span class="text-sm text-typo mx-2">{{
              item.count_employee
            }}</span>
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div class="text-xs text-body">
            {{ $t("project.start_time_permanence") + " :  " + item.start_time }}
          </div>
          <div class="text-xs text-body">
            {{ $t("project.end_time_permanence") + " :  " + item.end_time }}
          </div>
        </div>
      </v-col>
      <v-col cols="1">
        <v-btn icon v-if="edit" color="#82d616" @click="editWorkTime(item)">
          <v-icon size="18"> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="#ea0606" @click="deleteWorkTime(i, item)">
          <v-icon size="18"> mdi-delete </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    deleteWorkTime(index, item) {
      if (this.edit) {
        $emit("delete_work", item);
      } else {
        this.data = this.data.splice(index, 1);
      }
    },
    editWorkTime(item) {
      console.log("item", item);
      this.$emit("edit_work", item);
    },
  },
};
</script>